import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Navbar.module.css';

function Navbar() {
   const homeRef = useRef(null);
   const kawardsRef = useRef(null);
   const karnivalRef = useRef(null);
   const contactRef = useRef(null);
   const xceedRef = useRef(null);

   const mobileHomeRef = useRef(null);
   const mobileKawardsRef = useRef(null);
   const mobileKarnivalRef = useRef(null);
   const mobileContactRef = useRef(null);
   const mobileXceedRef = useRef(null);

   const mobileNavRef = useRef(null);
   const hamburgerRef = useRef(null);

   const location = useLocation();

   useEffect(() => {
      switch (location.pathname) {
         case '/':
            homeRef.current.classList.add(styles.active);
            kawardsRef.current.classList.remove(styles.active);
            karnivalRef.current.classList.remove(styles.active);
            xceedRef.current.classList.remove(styles.active);
            contactRef.current.classList.remove(styles.active);
            mobileHomeRef.current.classList.add(styles.active);
            mobileKawardsRef.current.classList.remove(styles.active);
            mobileKarnivalRef.current.classList.remove(styles.active);
            mobileXceedRef.current.classList.remove(styles.active);
            mobileContactRef.current.classList.remove(styles.active);
            break;
         case '/kawards':
            homeRef.current.classList.remove(styles.active);
            kawardsRef.current.classList.add(styles.active);
            karnivalRef.current.classList.remove(styles.active);
            xceedRef.current.classList.remove(styles.active);
            contactRef.current.classList.remove(styles.active);
            mobileHomeRef.current.classList.remove(styles.active);
            mobileKawardsRef.current.classList.add(styles.active);
            mobileKarnivalRef.current.classList.remove(styles.active);
            mobileXceedRef.current.classList.remove(styles.active);
            mobileContactRef.current.classList.remove(styles.active);
            break;
         case '/karnival':
            homeRef.current.classList.remove(styles.active);
            kawardsRef.current.classList.remove(styles.active);
            karnivalRef.current.classList.add(styles.active);
            xceedRef.current.classList.remove(styles.active);
            contactRef.current.classList.remove(styles.active);
            mobileHomeRef.current.classList.remove(styles.active);
            mobileKawardsRef.current.classList.remove(styles.active);
            mobileKarnivalRef.current.classList.add(styles.active);
            mobileXceedRef.current.classList.remove(styles.active);
            mobileContactRef.current.classList.remove(styles.active);
            break;
         case '/xceed':
            homeRef.current.classList.remove(styles.active);
            kawardsRef.current.classList.remove(styles.active);
            karnivalRef.current.classList.remove(styles.active);
            xceedRef.current.classList.add(styles.active);
            contactRef.current.classList.remove(styles.active);
            mobileHomeRef.current.classList.remove(styles.active);
            mobileKawardsRef.current.classList.remove(styles.active);
            mobileKarnivalRef.current.classList.remove(styles.active);
            mobileXceedRef.current.classList.add(styles.active);
            mobileContactRef.current.classList.remove(styles.active);
            break;
         case '/contact':
            homeRef.current.classList.remove(styles.active);
            kawardsRef.current.classList.remove(styles.active);
            karnivalRef.current.classList.remove(styles.active);
            xceedRef.current.classList.remove(styles.active);
            contactRef.current.classList.add(styles.active);
            mobileHomeRef.current.classList.remove(styles.active);
            mobileKawardsRef.current.classList.remove(styles.active);
            mobileKarnivalRef.current.classList.remove(styles.active);
            mobileXceedRef.current.classList.remove(styles.active);
            mobileContactRef.current.classList.add(styles.active);

            break;
         default:
            homeRef.current.classList.remove(styles.active);
            kawardsRef.current.classList.remove(styles.active);
            karnivalRef.current.classList.remove(styles.active);
            xceedRef.current.classList.remove(styles.active);
            contactRef.current.classList.remove(styles.active);
            mobileHomeRef.current.classList.remove(styles.active);
            mobileKawardsRef.current.classList.remove(styles.active);
            mobileKarnivalRef.current.classList.remove(styles.active);
            mobileXceedRef.current.classList.remove(styles.active);
            mobileContactRef.current.classList.remove(styles.active);
            break;
      }

   }, [location]);

   const toggleNav = (e) => {
      hamburgerRef.current.classList.toggle(styles.active);

      mobileNavRef.current.classList.toggle(styles.activeMob);


   };

   return (
      <>
         <div className={styles.nav}>
            <div className={styles.logo}>
               <img src="xceed_logo_dark.png" alt="Xceed Logo" onClick={() => (window.location.href = '/')} />
            </div>
            <div className={styles.navLinks}>
               <div ref={hamburgerRef} className={`${styles.nav_toggle}`} onClick={(e) => toggleNav(e)}>
                  <span></span>
               </div>
               <ul className={styles.navItems}>
                  <li className={styles.navItem}>
                     <Link ref={homeRef} className={styles.navLink} to="/">
                        Home
                     </Link>
                  </li>
                  <li className={styles.navItem}>
                     <Link ref={kawardsRef} className={styles.navLink} to="/kawards">
                        K!Awards
                     </Link>
                  </li>
                  <li className={styles.navItem}>
                     <Link ref={xceedRef} className={styles.navLink} to="/xceed">
                        Xceed
                     </Link>
                  </li>
                  <li className={styles.navItem}>
                     <Link ref={karnivalRef} className={`${styles.navLink}`} to="/karnival">
                        K!arnival
                     </Link>
                  </li>
                  <li className={styles.navItem}>
                     <Link ref={contactRef} className={styles.navLink} to="/contact">
                        Contact
                     </Link>
                  </li>
               </ul>

               <div>
                  <Link to="/registration">
                     <button className={styles.navButton}>Register Now</button>
                  </Link>
               </div>
            </div>

            <div className={`${styles.nav_mobile}`} ref={mobileNavRef}>
               <ul className={styles.navItems_mobile}>
                  <li className={styles.navItem_mobile}>
                     <Link
                        onClick={(e) => {
                           toggleNav(e);
                        }}
                        ref={mobileHomeRef}
                        className={styles.navLink}
                        to="/"
                     >
                        Home
                     </Link>
                  </li>
                  <li className={styles.navItem_mobile}>
                     <Link onClick={toggleNav} ref={mobileKawardsRef} className={styles.navLink} to="/kawards">
                        K!Awards
                     </Link>
                  </li>
                  <li className={styles.navItem_mobile}>
                     <Link onClick={toggleNav} ref={mobileXceedRef} className={styles.navLink} to="/xceed">
                        Xceed
                     </Link>
                  </li>
                  <li className={styles.navItem_mobile}>
                     <Link onClick={toggleNav} ref={mobileKarnivalRef} className={`${styles.navLink}`} to="/karnival">
                        K!arnival
                     </Link>
                  </li>
                  <li className={styles.navItem_mobile}>
                     <Link onClick={toggleNav} ref={mobileContactRef} className={styles.navLink} to="/contact">
                        Contact
                     </Link>
                  </li>
               </ul>

               <div>
                  <Link onClick={toggleNav} to="/registration">
                     <button className={styles.navButton_mobile}>Register Now</button>
                  </Link>
               </div>
            </div>
         </div>
      </>
   );
}

export default Navbar;
