import React, { useRef, useState } from 'react'

import styles from './Registration.module.css';
import ReCAPTCHA from "react-google-recaptcha";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import SimpleLoader from '../../components/SimpleLoader/SimpleLoader';
import { showErrorToastNotification, showSuccessToastNotification, showWarnToastNotification } from '../../components/ToastNotification/ToastNotification';
import { validateRegForm } from '../../validators/regForm';
import FormField from '../../components/FormField/FormField';

import nameIcon from "../../assets/icons/name.png";
import deptIcon from "../../assets/icons/department.png";
import yearIcon from "../../assets/icons/year.png";
import collegeIcon from "../../assets/icons/college.png";
import emailIcon from "../../assets/icons/email.png";
import phoneIcon from "../../assets/icons/phone.png";
import { apiRegisterUser } from '../../api/registerUser';
import RegistrationInfoPane from './RegistrationInfoPane';

const formStateFormat = {
	name: "",
	year: 1,
	dept: "",
	college: "",
	email: "",
	phone: "",
	x: "KVCET",
};

const Registration = () => {
	ScrollToTop();
	const [loading, setLoading] = useState(false);

	const [formState, setFormState] = useState(formStateFormat);
	const changeFormState = (args) => {
		let prevState = formState;
		prevState[args.key] = args.value;
		setFormState({ ...prevState });
	};


	let reCaptchaRef = useRef(null);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const validation = validateRegForm(formState);
		if (validation.status === false) {
			showWarnToastNotification(validation.message);
			return;
		}

		if (reCaptchaRef.current.getValue() === "") {
			showWarnToastNotification(<p>Please select reCaptcha</p>);
			return;
		}

		setLoading(true);

		const resp = await apiRegisterUser({
			...formState,
			captcha: reCaptchaRef.current.getValue(),
		});

		reCaptchaRef.current.reset();

		setLoading(false);

		if (resp === undefined) {
			showErrorToastNotification(<p>Please try again after sometime!</p>);
		} else {
			if (resp.status === 201) {
				showSuccessToastNotification(resp.data.message);
				setFormState(formStateFormat);
			} else if (resp.status >= 400 && resp.status < 500) {
				showErrorToastNotification(resp.data.message);
			} else if (resp.status >= 500 && resp.status < 600) {
				showErrorToastNotification(<p>Server Error. Try again after sometime!</p>);
			}
		}
	};

	return (
		<div class={`${styles.wrapper}`}>
			<div className={`${styles.title}`}>
				<h2>Register for Xceed - KVCET</h2>
			</div>
			<div className={styles.contentContainer}>
				{/* Left pane - form */}
				{loading && (
					<div className={styles.loader_container}>
						<SimpleLoader text="Registering..." />
					</div>
				)}
				<form
					className={`${styles.formContent}`}
					style={{ display: loading ? "none" : "flex" }}
				>
					<div className={styles.formHeader}>
						Get your XCEED ID here!
					</div>
					<a className={styles.infoLink} href="#regInfo">
						<div class={`${styles.button}`}>
							Click here for more info
						</div>
					</a>
					<FormField
						type="text"
						fieldIcon={nameIcon}
						placeholder="Full name"
						name="name"
						value={formState}
						setter={changeFormState}
					/>
					<FormField
						type="text"
						fieldIcon={deptIcon}
						placeholder="Department"
						name="dept"
						value={formState}
						setter={changeFormState}
					/>
					<FormField
						type="dropdown"
						dropdownValues={[
							{ name: "1st year", value: 1 },
							{ name: "2nd year", value: 2 },
							{ name: "3rd year", value: 3 },
							{ name: "4th year", value: 4 },
							{ name: "5th year", value: 5 },
						]}
						fieldIcon={yearIcon}
						placeholder="Year"
						name="year"
						value={formState}
						setter={changeFormState}
					/>
					<FormField
						type="text"
						fieldIcon={collegeIcon}
						placeholder="College"
						name="college"
						value={formState}
						setter={changeFormState}
					/>
					<FormField
						type="text"
						fieldIcon={emailIcon}
						placeholder="E-mail ID"
						name="email"
						value={formState}
						setter={changeFormState}
					/>
					<FormField
						type="phone"
						fieldIcon={phoneIcon}
						placeholder="Phone number"
						name="phone"
						value={formState}
						setter={changeFormState}
					/>
					<ReCAPTCHA
						sitekey={process.env.REACT_APP_CAPTCHA_KEY}
						theme="dark"
						size="normal"
						className={`${styles.recaptcha}`}
						ref={reCaptchaRef}
					/>
					<div id="regInfo">
						<div class={`${styles.button}`} onClick={handleSubmit}>
							Register
						</div>
					</div>
				</form>

				{/* Right pane - info */}
				<RegistrationInfoPane />
			</div>
		</div>
	)
};

export default Registration;
