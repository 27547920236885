export const KINDSOFXCEED = [
    {
        name: "National Xceed",
        link: 'national',
        image: "/XceedImages/techx1.jpg",
        description: "We visit Technical Institutes across the country!",
    },
    // {
    //     name: "School Xceed",
    //     link: 'school',
    //     image: "/ctf_logo_dark.png",
    //     description: "A state-wide hackathon for schools!",
    // },
    {
        name: "Managerial Xceed",
        link: 'managerial',
        image: "/XceedImages/manx1.jpg",
        description: "We visit Business Schools across the country!",
    },
    {
        name: "Corporate Xceed",
        link: 'corporate',
        image: "/XceedImages/corpx1.jpg",
        description: "We visit Corporates across the country!",
    },
];
