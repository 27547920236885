export const validateRegForm = ({
    name,
    dept,
    year,
    college,
    email,
    phone,
}) => {
    if (name.length === 0 && validateName(name)) {
        return {
            status: false,
            message: "Invalid Name",
        };
    }

    if (dept.length === 0) {
        return {
            status: false,
            message: "Invalid Dept",
        };
    }

    if (year.length === 0) {
        return {
            status: false,
            message: "Invalid Year",
        };
    }

    if (college.length === 0) {
        return {
            status: false,
            message: "Invalid College",
        };
    }

    if (email.length === 0) {
        return {
            status: false,
            message: "Invalid Email",
        };
    }

    if (phone.length === 0) {
        return {
            status: false,
            message: "Invalid Phone",
        };
    }

    return {
        status: true,
    };
};

const validateName = (name) => {
    var trimmedName = name.trim();
    var replacedName = name.replace(/^\s+|\s+$/gm, '');

    if (trimmedName !== name || replacedName !== name) {
        return false;
    }

    return true;
};
