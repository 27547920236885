import React from 'react'
import styles from "./Bg.module.css"

function Bg() {
  return (
    
<div className={`${styles.outerbody}`}>
    <div className={`${styles.stars}`}>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    <div className={`${styles.star}`}></div>
    </div>
</div>
  )
}

export default Bg;
