import React from 'react';
import CardComp from '../../components/CardComp/CardComp';
import { KAWARDS } from '../../data/KawardsData';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import UnderConstruction from '../../components/UnderConstruction/UnderConstruction';
import styles from './KAwards.module.css';

function KAwards() {
  ScrollToTop();

  return (
    <div className={`${styles.content}`}>
      <h1 className={`${styles.title}`}>K! Awards</h1>
      <h4 className={`${styles.desc}`}>
        K! Awards aims to encourage and recognize people who overcame hardships to do
        justice to their potential. The recipients of these awards include budding socially responsible
        innovators, educationalists, entrepreneurs, and social activists. This celebration is
        also embedded with mesmerizing performances of its kind putting the K! Awards
        in a league of its own, unlike other award ceremonies.
      </h4>
      <br />
      <br />
      {/* <UnderConstruction /> */}
      {KAWARDS.map((item, index) => {
        return <CardComp
          title={"K! Awards"}
          images={item.images}
          list={item.list}
        />
      })}
      <br />
      <br />
    </div>
  )
}

export default KAwards;
