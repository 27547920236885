import React from 'react';
import styles from "./RegistrationInfoPane.module.css";
import pdfIcon from "../../assets/icons/pdf.png";

const RegistrationInfoPane = () => {
	return (
		<section className={styles.wrapper}>
			<h3 className={styles.infoHeading}>A quick glimpse:</h3>
			<button className={styles.infoDocButton} onClick={() => { window.open("/XCEED_2023_KVCET_INFO.pdf") }}>
				<div className={styles.infoDocName}>
					<img src={pdfIcon} alt={""} />
					<span>
						&nbsp;XCEED_2023_KVCET_INFO.pdf
					</span>
				</div>
			</button>
			{/* <button className={styles.infoDocButton} onClick={() => { window.open("https://unstop.com/o/eAFlcyo?lb=yXrvncP") }}>
				<div className={styles.infoDocName}>
					<span>
						&nbsp;Pay now - ML and DS workshop
					</span>
				</div>
			</button>
			<button className={styles.infoDocButton} onClick={() => { window.open("https://unstop.com/o/hDFgBRN?lb=yXrvncP") }}>
				<div className={styles.infoDocName}>
					<span>
						&nbsp;Pay now - 3D Modelling workshop
					</span>
				</div>
			</button> */}
			<div className={styles.section}>
				<div className={styles.sectionHeading}>
					WORKSHOPS - Rs.200 for each workshop. Bring your own Laptop & Charger.
				</div>
				<ul className={styles.sectionList}>
					<li className={styles.sectionListItem}>3D Modelling - <b>(Hands-on)</b></li>
					{/* <li className={styles.sectionListItemPrereq}><b>Prerequisite:</b> Install Solidworks software.</li> */}
				</ul>
				<ul className={styles.sectionList}>
					<li className={styles.sectionListItem}>Machine Learning and Data Science - <b>(Hands-on)</b></li>
					{/* <li><b> Prerequisites:</b></li>
					<li className={styles.sectionListItemPrereq}> Install <span className={styles.PrereqURL} onClick={() => window.open("https://portswigger.net/burp/releases/professional-community-2022-12-7?requestededition=community&requestedplatform=")}>Burp suite community edition</span></li>
					<li className={styles.sectionListItemPrereq}> Install a python IDE</li>
					<li className={styles.sectionListItemPrereq}> Sign-Up in <span className={styles.PrereqURL} onClick={() => window.open("https://tryhackme.com")}>tryhackme.com</span></li>
					<li className={styles.sectionListItemPrereq}> Sign-Up in <span className={styles.PrereqURL} onClick={() => window.open("https://shodan.io")}>shodan.io</span></li>*/}
				</ul>
			</div>
			<div className={styles.section}>
				<div className={styles.sectionHeading}>
					EVENTS - <b>Free to participate!</b>
				</div>
				<ul className={styles.sectionList}>
					<li className={styles.sectionListItem}>
						Kryptics - KRYPTICS is a game based on engineering themes, principles, technical words and facts in which students must use a set of clues to find the whole word or name.
					</li>
				</ul>
				<ul className={styles.sectionList}>
					<li className={styles.sectionListItem}>
						Pair coding - Test your techie skills in this event where you take turns coding to solve the problem statement.
					</li>
				</ul>
				<ul className={styles.sectionList}>
					<li className={styles.sectionListItem}>
						Project Display Competition - Showcase your innovation and efforts in this project display competition. The best projects will be selected based on various parameters for exciting cash prizes.
					</li>
				</ul>
			</div>
		</section>
	)
}

export default RegistrationInfoPane;