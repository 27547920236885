import React from 'react'
import { Link } from 'react-router-dom'
import { KINDSOFXCEED } from '../../data/XceedKinds';
import styles from './XceedInfo.module.css';
function XceedInfo() {
    return (
        <div>
            <div className={`${styles.xceedInfoContainer}`}>
                <h1 className={`${styles.xceedInfoHeader}`}>XCEED</h1>
                <br />
                <p className={`${styles.xceedInfoDescription}`}>
                    K! proudly presents Xceed as a step to bring out the best minds excelling in
                    technical, analytical, creative, and coding abilities who will tackle real-
                    world problems. Xceed encompasses enthralling events of various categories and invigorative
                    workshops on cutting-edge technological topics. It will be held in various colleges and institutions at the
                    national level along with career guidance sessions, K-Talks, and a display of the
                    best projects of previous years.
                </p>
            </div>
            <div className={`${styles.xceedTypeContainer}`}>
                {
                    KINDSOFXCEED.map((kind, index) => {
                        return (<XceedKind key={index} kind={kind} />);
                    })
                }
            </div>
        </div>
    )
}

const XceedKind = ({ kind }) => {
    return (
        <div className={`${styles.xceedTypeCard}`}>
            <Link to={`/xceed/${kind.link}`}>
                <img src={kind.image}
                    alt={kind.name}
                    className={`${styles.xceedTypeYearWiseImage}`}
                />

                {/* Type of xceed */}
                <h2 className={`${styles.xceedTypeTitle}`}>{kind.name}</h2>

                {/* About that particular xceed type */}
                <p className={`${styles.xceedTypeDescription}`}>{kind.description}</p>
            </Link>
        </div>
    )
};

export default XceedInfo